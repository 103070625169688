import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  img_thumb,
  author,
}) => {
  const PostContent = contentComponent || Content

  return (

<article className="article">
  {helmet || ''}
      <div className="article-header">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-7-fullhd is-9-widescreen is-10-tablet">
              <nav className="breadcrumb is-medium">
                <ul>
                  <li> 
                    <Link to="/blog"><i className="icon icon-home is-small"></i><span>Blog</span></Link>
                  </li>
                  <li className="is-active"> 
                  
                  <span><Link to="tag/opensource/">Open Source</Link></span>
                  
                  </li>
                </ul>
              </nav>
              <div className="content is-medium is-blog">
                <h1 className="title is-spaced"> {title}</h1>
                <p className="subtitle is-4"> Did you ever wonder why your child feels so different? And is being
                  different something to be shy about? Stories help children to process ideas that sometimes are
                  difficult to talk about. </p>
                <p> <small>Posted by {author}</small> </p>
               </div>
             </div>
           </div>
         </div>
       </div>
      <div className="article-cover">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-7-fullhd is-9-widescreen is-10-tablet">
              <div className="article-image"> 
               <img src={img_thumb} alt={title} />
              </div>
             </div>
           </div>
         </div>
       </div>
      <div className="article-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-7-fullhd is-9-widescreen is-10-tablet">
              <div className="content is-medium is-blog">
              
                {description}
                 <PostContent content={content} />
                  {tags && tags.length ? (
               <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                   <li key={tag + `tag`}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                    ))}
               </ul>
              </div>
          ) : null}
            </div>
          </div>
          </div>
        </div>
      </div>
      

    </article>

  
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.string,
  img_thumb: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet
            titleTemplate="%s | Blog"
          >
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        img_thumb={post.frontmatter.img_thumb}
        author={post.frontmatter.author}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        author
        description
        tags
      }
    }
  }
`
